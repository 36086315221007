// import {
//   languages,
//   routerAnimations,
// } from './data';

const state = {
  darkMode: true,
  collapseSidebar: false,
};

// getters
const getters = {
  darkMode: state => {
    return state.darkMode;
  },
  collapseSidebar: state => {
    return state.collapseSidebar;
  }
};

// actions
const actions = {
  // collapseSidebar(context) {
  //   context.commit('collapseSidebarHandler');
  // },
};

// mutations
const mutations = {
  collapseSidebarHandler(state, payload) {
    // state.collapseSidebar = !state.collapseSidebar;
    state.collapseSidebar = payload
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
