import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'PageHome',
    component: () => import(/* webpackChunkName: "page-home" */ '../components/PageHomeEsp.vue')
  },
  {
    path: '/cat',
    name: 'PageHomeCat',
    component: () => import(/* webpackChunkName: "page-home" */ '../components/PageHomeCat.vue')
  },
  {
    path: '/aviso-legal-esp',
    name: 'AvisoLegal',
    component: () => import(/* webpackChunkName: "page-home" */ '../components/PageAvisoLegalEsp.vue')
  },
  {
    path: '/aviso-legal-cat',
    name: 'AvisoLegal',
    component: () => import(/* webpackChunkName: "page-home" */ '../components/PageAvisoLegalCat.vue')
  },
  {
    path: '/email-signatures',
    name: 'PageEmailSignatures',
    component: () => import(/* webpackChunkName: "page-email-signatures" */ '../components/PageEmailSignatures.vue')
  }
];

export const router = new VueRouter({
  mode: 'history',
  routes,
  // scrollBehavior() {
  //   return {x: 0, y: 0}
  // }
});

export default router
